<template>
    <div>
      <event-header
      >
        <span v-if="false" class="result-title">Résultats de l'épreuve : {{ event.EVENT.name }}</span>
      </event-header>
      <H1>KEEP THIS</H1>
      <div class="result-container">
            <detailed-results
                    :category = "category"
                    :event = "event"
                    class="results-container"
            >
                <template #title>
                    <h4 v-if="false" class="title">{{ event.EVENT.name }} - {{ event.CATEGORY.name }}</h4>
                </template>
            </detailed-results>
        </div>
    </div>
</template>

<script>
import detailedResults from '@/components/ScoringGrid/DetailedResults'
import EventHeader from './Components/EventHeader.vue';

export default {
    components:{ detailedResults, EventHeader/*, RoundHeader,*/ },
    computed:{
//        ...mapGetters('current', ['currentRound', 'currentLevel', 'currentCategory', 'currentEvent']),
        event() {
            return this.currentEvent;
            },
        category() {
            return this.currentCategory;
            },
    },
    mounted(){
      this.$hideLoader();
    }
}
</script>

<style scoped >
    div { font-size: 0.7rem;}
    @media screen and (max-width: 767px) and (orientation: portrait) {
        .results-container {
            /*transform: rotate(90deg);*/
            /*transform-origin: top left;*/
            /*transform-origin: left bottom;*/
            /*width: 100 vh;*/
            /*overflow-x: hidden;*/
            /*position: absolute;
            left:0;*/
            /*top: 170px;*/
            /*top: 100%;*/
            background-color: aliceblue;
            /*margin-top:200px;*/
            /*display:flex;
            justify-content: center;
            align-content: center;*/
            /*orientation: landscape;*/
            margin: 20px;
         }
    }
</style>
